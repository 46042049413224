import React, { useEffect, useState } from 'react'
import { DeleteOutlined, EditOutlined, MenuOutlined } from '@ant-design/icons'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import {
    SortableContext,
    arrayMove,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { Button, Spin, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Image } from 'antd'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { useNavigate } from 'react-router'
import { IBannerElement } from '../../../model/interface/bannerElement'
import { BannerService } from '../../../service/banner.service'
import { notificationMessage } from '../../../components/notification'
import ConfirmationModal, {
    ActionType,
} from '../../../components/confirmationModal'
import Empty from 'antd/lib/empty'

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string
}

interface BannerListProps {
    isEditPriority: boolean
    isCancel: boolean
    isConfirm: boolean
    onMaxBannerChange: (isMaxBanner: boolean) => void
    onEditPriorityChange: (isEditPriority: boolean) => void
    onConfirmChange: (isConfirm: boolean) => void
    setConfirmLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const Row = ({ children, ...props }: RowProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props['data-row-key'],
    })

    const style: React.CSSProperties = {
        ...props.style,
        transform: transform ? `scaleY(1) ${transform}` : undefined,
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 100 } : {}),
    }

    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
                if ((child as React.ReactElement).key === 'sort') {
                    return React.cloneElement(child as React.ReactElement, {
                        children: (
                            <MenuOutlined
                                ref={setActivatorNodeRef}
                                style={{ touchAction: 'none', cursor: 'move' }}
                                {...listeners}
                            />
                        ),
                    })
                }
                return child
            })}
        </tr>
    )
}

export const BannerList = (props: BannerListProps): JSX.Element => {
    const {
        isEditPriority,
        isCancel,
        isConfirm,
        onMaxBannerChange,
        onEditPriorityChange,
        onConfirmChange,
        setConfirmLoading
    } = props
    const [dataSource, setDataSource] = useState<IBannerElement[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [isMaxBanner, setIsMaxBanner] = useState<boolean>(false)
    const navigate = useNavigate()
    const onEdit = (id: string) => navigate(`/banner/edit/${id}`)

    const showConfirmEdit = () => {
        ConfirmationModal({
            mode: 'update' as ActionType,
            title: 'ลำดับ Banner',
            onOk: () => {
                handleEditPriority()
            },
            onCancel: () => onConfirmChange(false),
        })
    }

    const handleEditPriority = async () => {
        setConfirmLoading(true)
        const bannerIds = dataSource.map((i) => i.id)
        try {
            await BannerService.updateBannerPriority(bannerIds).then(() => {
                notificationMessage({
                    type: 'success',
                    message: 'แก้ไขลำดับ Banner สำเร็จ',
                })
            })
            onConfirmChange(false)
            onEditPriorityChange(false)
        } catch {
            notificationMessage({
                type: 'error',
                message: 'แก้ไขลำดับ Banner ไม่สำเร็จ',
            })
            console.log('Error updating banner priority')
        } finally {
            setConfirmLoading(false) 
            navigate(`/banner`)
        }
    }

    const handleDelete = async (id: string) => {
        try {
            await BannerService.deleteBanner(id).then(() => {
                notificationMessage({
                    type: 'success',
                    message: 'ลบ Banner สำเร็จ',
                })
            })
            fetchData()
        } catch (error) {
            notificationMessage({
                type: 'error',
                message: 'ลบ Banner ไม่สำเร็จ',
            })
            console.error('Error deleting blog:', error)
        }
    }

    const showDeleteConfirm = (id: string) => {
        ConfirmationModal({
            mode: 'delete' as ActionType,
            title: 'Banner',
            onOk: () => handleDelete(id),
            okType: 'danger',
        })
    }

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (
            isEditPriority == true ||
            (active.id !== over?.id && isEditPriority)
        ) {
            setDataSource((previous) => {
                const activeIndex = previous.findIndex(
                    (i) => i.id === active.id
                )
                const overIndex = previous.findIndex((i) => i.id === over?.id)
                return arrayMove(previous, activeIndex, overIndex)
            })
        }
    }

    const fetchData = async () => {
        try {
            setLoading(true)
            const res = await BannerService.getBanner()
            setDataSource(res.data)
            setLoading(false)
            const isMax = res.data.length >= 5
            setIsMaxBanner(isMax)
            onMaxBannerChange(isMaxBanner)
        } catch {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [isCancel])

    useEffect(() => {
        if (isConfirm) {
            showConfirmEdit()
        }
    }, [isConfirm])

    const columns: ColumnsType<IBannerElement> = [
        {
            key: 'sort',
            width: '28px',
        },
        {
            title: 'No.',
            dataIndex: 'number',
            width: '80px',
            render: (_, __, index) => index + 1,
        },
        {
            title: 'รูป Banner สำหรับ Desktop',
            dataIndex: 'bannerImgDesktop',
            width: '200px',
            render: (_, record) => (
                <Image
                    width={200}
                    style={{
                        aspectRatio: '16/9',
                    }}
                    src={record.imageUrlDesktop}
                />
            ),
        },
        {
            title: 'รูป Banner สำหรับ Mobile',
            dataIndex: 'bannerImgMobile',
            width: '200px',
            render: (_, record) =>
                record.imageUrlMobile ? (
                    <Image
                        width={200}
                        style={{
                            aspectRatio: '16/9',
                        }}
                        src={record.imageUrlMobile}
                    />
                ) : (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="ไม่มีข้อมูล"
                    />
                ),
        },
        {
            title: 'ชื่อ Banner',
            dataIndex: 'bannerName',
            width: '240px',
            render: (_, record) => (
                <div style={{ lineBreak: 'auto', width: '100px' }}>
                    {record.imageName}
                </div>
            ),
        },
        {
            title: 'Deeplink',
            dataIndex: 'deeplink',
            width: '200px',
            render: (_, record) => (
                <div style={{ lineBreak: 'auto', width: '200px' }}>
                    {record.deepLink ? record.deepLink : '-'}
                </div>
            ),
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: '200px',
            render: (_: string, banner) => (
                <div>
                    <Button
                        type="dashed"
                        size="middle"
                        style={{ marginRight: '10px' }}
                        onClick={() => onEdit(banner.id)}
                    >
                        <EditOutlined style={{ fontSize: '18px' }} />
                        แก้ไข
                    </Button>
                    <Button
                        danger
                        type="dashed"
                        size="middle"
                        style={{ color: 'red' }}
                        onClick={() => {
                            showDeleteConfirm(banner.id)
                        }}
                    >
                        <DeleteOutlined style={{ fontSize: '18px' }} />
                        ลบ
                    </Button>
                </div>
            ),
        },
    ]

    return (
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
                items={dataSource.map((i) => i.id)}
                strategy={verticalListSortingStrategy}
            >
                <Spin spinning={loading} />
                <Table
                    components={{
                        body: {
                            row: Row,
                        },
                    }}
                    scroll={{ x: 1400 }}
                    rowKey="id"
                    columns={columns}
                    dataSource={dataSource}
                />
            </SortableContext>
        </DndContext>
    )
}
