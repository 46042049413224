import React, { useEffect, useState } from 'react'
import { InboxOutlined } from '@ant-design/icons'
import { UploadProps, message, Upload } from 'antd'
import { UploadFile } from 'antd/lib'

const { Dragger } = Upload

export const UploadImg = ({
    onUploadImgSuccess,
    onUploadImgDelete,
    initialValue,
    forMobile,
}: {
    onUploadImgSuccess: (url: string) => void
    onUploadImgDelete: (id: string) => void
    initialValue?: string | undefined
    forMobile: boolean
}): JSX.Element => {
    const [imageUrl, setImageUrl] = useState<string>('')
    const [imageID, setImageID] = useState<string>('')
    const [previewImageContent, setPreviewImageContent] = useState<
        UploadFile[]
    >([])

    useEffect(() => {
        if (initialValue) {
            const urlSegments = initialValue.split('/')
            const lastSegment = urlSegments[urlSegments.length - 1]
            const fileName = lastSegment.split('?')[0]
            setImageUrl(initialValue)
            setPreviewImageContent([
                {
                    url: initialValue,
                    uid: '',
                    name: fileName,
                },
            ])
        }
    }, [initialValue])

    const uploadProps: UploadProps = {
        name: 'image',
        multiple: false,
        maxCount: 1,
        accept: 'image/jpg, image/png, image/jpeg',
        action: `${process.env.REACT_APP_API_URL}/api/admin/upload`,
        fileList: previewImageContent,
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
                'access_token'
            )}`,
            accept: 'application/json',
        },
        onChange(info) {
            const { status, response } = info?.file
            if (status === 'done') {
                const { url, id } = response
                onUploadImgSuccess(url)
                setImageUrl(url)
                setImageID(id)
                message.success(`${info.file.name} อัปโหลดไฟล์เสร็จสิ้น`)
            } else if (status === 'error') {
                const { url } = response
                message.error(`${info.file.name} อัปโหลดไฟล์ล้มเหลม`)
                setImageUrl(url)
            }
            setPreviewImageContent(info.fileList)
        },
        onPreview: async (file: UploadFile) => {
            window.open(file.url || file.response.url, '_blank')
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files)
        },
        onRemove: () => {
            onUploadImgDelete(imageID)
            setImageID('')
            return true
        },
    }

    return (
        <>
            <Dragger
                {...uploadProps}
                style={{
                    width: '507px',
                    height: '285px',
                }}
            >
                {previewImageContent.length === 0 && (
                    <>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            เลือกไฟล์หรือลากมาที่นี่
                        </p>
                        <p className="ant-upload-hint">
                            อัตราส่วนที่แนะนำคือ {forMobile ? '4:5' : '16:9'}{' '}
                            <br />
                            รองรับการอัปโหลดไฟล์ PNG, JPG, JPEG ครั้งละ 1
                            รูปภาพเท่านั้น
                        </p>
                    </>
                )}
                {previewImageContent.length > 0 && (
                    <img
                        src={imageUrl}
                        alt="Preview"
                        style={{ width: '100%' }}
                    />
                )}
            </Dragger>
        </>
    )
}
