import React from 'react'
import { DeleteOutlined, EditOutlined, MenuOutlined } from '@ant-design/icons'
import { useSortable } from '@dnd-kit/sortable'
import { Button, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Image } from 'antd'
import { useNavigate } from 'react-router'
import {
    IProductElement,
    ProductApiResponse,
} from '../../../../model/interface/product'
import { ProductService } from '../../../../service/product.service'
import { notificationMessage } from '../../../../components/notification'
import ConfirmationModal, {
    ActionType,
} from '../../../../components/confirmationModal'
import { AxiosError } from 'axios'

interface ProductListProps {
    dataSource: ProductApiResponse | undefined
    setFilterAllProduct: React.Dispatch<
        React.SetStateAction<{
            page: number
            pageSize: number
            search: string
            id: string
            pagination: boolean
        }>
    >
    fetchData: () => void
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string
}

const Row = ({ children, ...props }: RowProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props['data-row-key'],
    })

    const style: React.CSSProperties = {
        ...props.style,
        transform: transform ? `scaleY(1) ${transform}` : undefined,
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 100 } : {}),
    }

    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
                if ((child as React.ReactElement).key === 'sort') {
                    return React.cloneElement(child as React.ReactElement, {
                        children: (
                            <MenuOutlined
                                ref={setActivatorNodeRef}
                                style={{ touchAction: 'none', cursor: 'move' }}
                                {...listeners}
                            />
                        ),
                    })
                }
                return child
            })}
        </tr>
    )
}

export const AllProductList = (props: ProductListProps): JSX.Element => {
    const { dataSource, setFilterAllProduct, fetchData } = props

    const navigate = useNavigate()
    const onEdit = (id: string) => navigate(`/product/edit/${id}`)

    const columns: ColumnsType<IProductElement> = [
        {
            title: 'รูปภาพของสินค้า',
            dataIndex: 'bannerImg',
            width: '200px',
            render: (_, record) => (
                <Image
                    width={200}
                    style={{ aspectRatio: '16/9', objectFit: 'cover' }}
                    src={record.banner}
                />
            ),
        },
        {
            title: 'ชื่อสินค้า',
            dataIndex: 'title',
            width: '200px',
            sorter: (a, b) => a.title.localeCompare(b.title),
        },
        {
            title: 'หมวดหมู่',
            dataIndex: 'category',
            width: '150px',
            render: (_, record) => (
                <div style={{ lineBreak: 'auto', width: '100px' }}>
                    {record.category.name}
                </div>
            ),
        },
        {
            title: 'ราคา',
            dataIndex: 'number',
            width: '100px',
            sorter: (a, b) => a.price - b.price,
            render: (_, record) => (
                <div style={{ lineBreak: 'auto', width: '100px' }}>
                    {record.price.toLocaleString()}
                </div>
            ),
        },
        {
            title: 'เผยแพร่โดย',
            dataIndex: 'auther',
            width: '130px',
            render: (_, record) => (
                <div style={{ lineBreak: 'auto', width: '130px' }}>
                    {record.createBy.email || '-'}
                </div>
            ),
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: '180px',
            render: (_: string, product) => (
                <div style={{ display: 'flex' }}>
                    <Button
                        type="dashed"
                        size="middle"
                        style={{ marginRight: '10px' }}
                        onClick={() => onEdit(product.id)}
                    >
                        <EditOutlined style={{ fontSize: '18px' }} />
                        แก้ไข
                    </Button>
                    <Button
                        danger
                        type="dashed"
                        size="middle"
                        style={{ color: 'red' }}
                        onClick={() => showDeleteConfirm(product.id)}
                    >
                        <DeleteOutlined style={{ fontSize: '18px' }} />
                        ลบ
                    </Button>
                </div>
            ),
        },
    ]

    const handleDelete = async (id: string) => {
        try {
            await ProductService.deleteProduct(id)
            fetchData()
            notificationMessage({
                type: 'success',
                message: 'ลบสินค้าสำเร็จ',
            })
        } catch (error) {
            const axiosError = error as AxiosError<{
                code: number
                message: string
            }>
            switch (axiosError?.response?.data.message) {
                case 'this product is in product another':
                    return notificationMessage({
                        type: 'error',
                        message:
                            'ลบสินค้าไม่สำเร็จ เนื่องจากสินค้านี้อยู่ในสินค้าอื่นๆ',
                    })
                    case 'this product is in product recommendation':
                        return notificationMessage({
                            type: 'error',
                            message:
                                'ลบสินค้าไม่สำเร็จ เนื่องจากสินค้านี้อยู่ในสินค้าแนะนำ',
                        })
                default:
                    return notificationMessage({
                        type: 'error',
                        message: 'ลบสินค้าไม่สำเร็จ',
                    })
            }
        }
    }

    const showDeleteConfirm = (id: string) => {
        ConfirmationModal({
            mode: 'delete' as ActionType,
            title: 'สินค้า',
            onOk() {
                handleDelete(id)
            },
        })
    }

    const handlePageChange = (page: number, pageSize: number) => {
        setFilterAllProduct((prevFilter) => ({
            ...prevFilter,
            page,
            pageSize,
        }))
    }

    return (
        <>
            <Table
                components={{
                    body: {
                        row: Row,
                    },
                }}
                rowKey="id"
                columns={columns}
                dataSource={dataSource?.data}
                pagination={{
                    current: dataSource?.page,
                    pageSize: dataSource?.pageSize,
                    total: dataSource?.total,
                    onChange: handlePageChange,
                }}
            />
        </>
    )
}
