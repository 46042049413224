import { PlusOutlined } from '@ant-design/icons'
import { UploadFile, UploadProps, Upload, Modal, FormInstance } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import React from 'react'

interface InputPicCardUploadImgProps {
    form: FormInstance
    name: string
    initialValue?: string[] | string
    maxImg: number
}

const getBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = (error) => reject(error)
    })

const InputPicCardUploadImg: React.FC<InputPicCardUploadImgProps> = ({
    form,
    name,
    initialValue,
    maxImg,
}) => {
    const [previewOpen, setPreviewOpen] = React.useState(false)
    const [previewImage, setPreviewImage] = React.useState('')
    const [previewTitle, setPreviewTitle] = React.useState('')
    const [fileList, setFileList] = React.useState<UploadFile[]>([])

    const handleCancel = () => setPreviewOpen(false)

    const filterInitialValue = (
        initialValue: string | string[] | undefined
    ) => {
        if (typeof initialValue === 'string') {
            if (initialValue === '') {
                return null
            }
            return [initialValue]
        } else {
            return initialValue
        }
    }

    React.useEffect(() => {
        const initfiled = filterInitialValue(initialValue)
        if (initfiled) {
            const initialFiles = initfiled.map((value, index) => ({
                url: value,
                uid: index.toString(),
                name: value.split('/').pop()?.split('_').shift() || '',
            }))
            setFileList(initialFiles)
            setPreviewImage(initfiled[0])
        }
    }, [initialValue])

    const uploadProps: UploadProps = {
        name: 'image',
        multiple: false,
        accept: 'image/jpg, image/png, image/jpeg',
        listType: 'picture-card',
        action: `${process.env.REACT_APP_API_URL}/api/admin/upload`,
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
                'access_token'
            )}`,
        },
        maxCount: maxImg,
        fileList: fileList,
        onChange: (info: UploadChangeParam) => {
            setFileList(info.fileList)
            const addedFiles = info.fileList.filter(
                (file) => !file.url && !!file.response
            )
            if (maxImg === 1) {
                if (addedFiles && addedFiles[0]?.response) {
                    const { url } = addedFiles[0]?.response
                    form.setFieldValue(name, url)
                }
            } else {
                const urls = addedFiles
                    .map((file) => file.response?.url)
                    .filter(Boolean) as string[]
                form.setFieldValue(name, urls)
            }
        },
        onPreview: async (file: UploadFile) => {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj as File)
            }
            setPreviewImage(file.url || (file.preview as string))
            setPreviewOpen(true)
            setPreviewTitle(
                file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
            )
        },
        onRemove: () => {
            form.setFieldValue(name, undefined)
        },
    }

    return (
        <div style={{ width: '507px' }}>
            <Upload {...uploadProps}>
                {fileList.length >= maxImg ? null : (
                    <button
                        style={{ border: 0, background: 'none' }}
                        type="button"
                    >
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                    </button>
                )}
            </Upload>
            <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img
                    alt="example"
                    style={{ width: '100%' }}
                    src={previewImage}
                />
            </Modal>
        </div>
    )
}

export default InputPicCardUploadImg
