import { FormInstance, Upload, UploadFile, UploadProps, message } from 'antd'
import React from 'react'
import { InboxOutlined } from '@ant-design/icons'
import { UploadChangeParam } from 'antd/lib/upload'

const { Dragger } = Upload

interface InputDragUploadImgProps {
    form: FormInstance
    name: string
    initialValue?: string
    forMobile: boolean
}

const InputDragUploadImg: React.FC<InputDragUploadImgProps> = ({
    form,
    name,
    initialValue,
    forMobile,
}) => {
    const [imageUrl, setImageUrl] = React.useState<string>('')
    const [fileList, setFileList] = React.useState<UploadFile[]>([])

    React.useEffect(() => {
        if (initialValue) {
            const urlSegments = initialValue.split('/')
            const lastSegment = urlSegments[urlSegments.length - 1]
            const fileName = lastSegment.split('?')[0]
            setImageUrl(initialValue)
            setFileList([
                {
                    url: initialValue,
                    uid: '',
                    name: fileName,
                },
            ])
        }
    }, [form, initialValue, name])

    const uploadProps: UploadProps = {
        name: 'image',
        multiple: false,
        accept: 'image/jpg, image/png, image/jpeg',
        action: `${process.env.REACT_APP_API_URL}/api/admin/upload`,
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
                'access_token'
            )}`,
        },
        maxCount: 1,
        fileList: fileList,
        onChange: (info: UploadChangeParam) => {
            const { status, response } = info?.file
            if (status === 'done') {
                const { url } = response
                setImageUrl(url)
                message.success(`${info.file.name} อัปโหลดไฟล์เสร็จสิ้น`)
                form.setFieldValue(name, url)
            } else if (status === 'error') {
                const { url } = response
                message.error(`${info.file.name} อัปโหลดไฟล์ล้มเหลว`)
                setImageUrl(url)
            }
            setFileList(info.fileList)
        },
        onPreview: async (file: UploadFile) => {
            window.open(file.url || file.response.url, '_blank')
        },
        onRemove: () => {
            form.setFieldValue(name, undefined)
        },
    }
    const styles = {
        color: `${form.getFieldError(name).length != 0 ? 'red' : ''}`,
    }
    return (
        <Dragger
            maxCount={1}
            {...uploadProps}
            style={{
                width: '507px',
                height: '285px',
                borderColor: `${
                    form.getFieldError(name).length != 0 ? 'red' : ''
                }`,
            }}
        >
            {fileList.length === 0 ? (
                <>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined style={styles} />
                    </p>
                    <p className="ant-upload-text">เลือกไฟล์หรือลากมาที่นี่</p>
                    <p className="ant-upload-hint">
                        อัตราส่วนที่แนะนำคือ {forMobile ? '4:5' : '16:9'}
                        <br />
                        รองรับการอัปโหลดไฟล์ PNG, JPG, JPEG เท่านั้น
                    </p>
                </>
            ) : (
                <img src={imageUrl} alt="Preview" style={{ width: '100%' }} />
            )}
        </Dragger>
    )
}

export default InputDragUploadImg
