import React, { useState } from 'react'
import { MenuOutlined } from '@ant-design/icons'
import { useSortable } from '@dnd-kit/sortable'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { Button, Modal, Table } from 'antd'
import { notificationMessage } from '../../../../components/notification'
import { verifyStatus } from '../../../../model/enum/payment'
import {
    PaymentApiResponse,
    SlipOkRes,
    IPaymentElement,
} from '../../../../model/interface/payment'
import { PaymentService } from '../../../../service/payment.service'
import { AxiosError } from 'axios'
import { ProductService } from '../../../../service/product.service'

interface PaymentListProps {
    dataSource: PaymentApiResponse | undefined
    setFilterAllPayment: React.Dispatch<
        React.SetStateAction<{
            page: number
            pageSize: number
            userId?: string
            productId?: string
            status?: string
            startDate?: string
            endDate?: string
            refId?: string
        }>
    >
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string
}

const Row = ({ children, ...props }: RowProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props['data-row-key'],
    })

    const style: React.CSSProperties = {
        ...props.style,
        transform: transform ? `scaleY(1) ${transform}` : undefined,
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 100 } : {}),
    }

    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
                if ((child as React.ReactElement).key === 'sort') {
                    return React.cloneElement(child as React.ReactElement, {
                        children: (
                            <MenuOutlined
                                ref={setActivatorNodeRef}
                                style={{ touchAction: 'none', cursor: 'move' }}
                                {...listeners}
                            />
                        ),
                    })
                }
                return child
            })}
        </tr>
    )
}

const SlipOkResDetails = ({ slipOkRes }: { slipOkRes: SlipOkRes }) => {
    return (
        <div>
            <p>
                <b>Success:</b> {slipOkRes.success.toString()}
            </p>
            <p>
                <b>Message:</b> {slipOkRes.message}
            </p>
            <p>
                <b>Language:</b> {slipOkRes.language}
            </p>
            <p>
                <b>Receiving Bank:</b> {slipOkRes.receivingBank}
            </p>
            <p>
                <b>Sending Bank:</b> {slipOkRes.sendingBank}
            </p>
            <p>
                <b>Transfer Reference:</b> {slipOkRes.transferRef}
            </p>
            <p>
                <b>Transaction Date:</b> {slipOkRes.transDate}
            </p>
            <p>
                <b>Transaction Time:</b> {slipOkRes.transTime}
            </p>
            <p>
                <b>Transaction Timestamp:</b> {slipOkRes.transTimestamp}
            </p>
            <p>
                <b>Sender:</b> {slipOkRes.sender.displayName} (
                {slipOkRes.sender.name})
            </p>
            <p>
                <b>Receiver:</b> {slipOkRes.receiver.displayName} (
                {slipOkRes.receiver.name})
            </p>
            <p>
                <b>Amount:</b> {slipOkRes.amount}
            </p>
            <p>
                <b>Paid Local Amount:</b> {slipOkRes.paidLocalAmount}
            </p>
            <p>
                <b>Paid Local Currency:</b> {slipOkRes.paidLocalCurrency}
            </p>
            <p>
                <b>Country Code:</b> {slipOkRes.countryCode}
            </p>
        </div>
    )
}

export const PaymentList = (props: PaymentListProps): JSX.Element => {
    const { dataSource, setFilterAllPayment } = props
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalContent, setModalContent] = useState<SlipOkRes | null>(null)
    const [isApprovalModalVisible, setIsApprovalModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] =
        useState<IPaymentElement | null>(null)
    const [loadingRow, setLoadingRow] = useState<{ [key: string]: boolean }>({})

    const showModal = (content: SlipOkRes) => {
        setModalContent(content)
        setIsModalVisible(true)
    }

    const handleModalOk = () => {
        setIsModalVisible(false)
        setModalContent(null)
    }

    const handleModalCancel = () => {
        setIsModalVisible(false)
        setModalContent(null)
    }

    const showApprovalModal = (record: IPaymentElement) => {
        setSelectedRecord(record)
        setIsApprovalModalVisible(true)
    }

    const handleApprovalOk = async () => {
        if (selectedRecord) {
            await handleApprove(selectedRecord)
            setIsApprovalModalVisible(false)
            setSelectedRecord(null)
        }
    }

    const handleApprovalCancel = async () => {
        if (selectedRecord) {
            await handleReject(selectedRecord)
            setIsApprovalModalVisible(false)
            setSelectedRecord(null)
        }
    }

    const fetchData = async () => {
        try {
            const product = await PaymentService.getPayments(
                1,
                10,
                '',
                '',
                '',
                '',
                '',
                ''
            )
            setFilterAllPayment(product)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    const handleApprove = async (record: IPaymentElement) => {
        try {
            await PaymentService.putPayment(record.refId, verifyStatus.VERIFIED)
            notificationMessage({
                type: 'success',
                message: 'อัปเดตสถานะการจ่ายเงินสำเร็จ',
            })
            fetchData()
        } catch (error) {
            const axiosError = error as AxiosError<{
                code: number
                message: string
            }>

            switch (axiosError?.response?.data.message) {
                case 'ไม่สามารถอนุมัติการจ่ายเงินได้ เนื่องจากผู้ใช้งานได้ทำการยกเลิกรายการเรียบร้อยแล้ว':
                    notificationMessage({
                        type: 'error',
                        message:
                            'ไม่สามารถอนุมัติการจ่ายเงินได้ เนื่องจากผู้ใช้งานได้ทำการยกเลิกรายการเรียบร้อยแล้ว',
                    })
                    window.location.reload()
                    break
                default:
                    notificationMessage({
                        type: 'error',
                        message: 'อัปเดตสถานะการจ่ายเงินไม่สำเร็จ',
                    })
                    window.location.reload()
                    break
            }
        }
    }

    const handleDownloadFile = async (id: string) => {
        setLoadingRow((prevLoading) => ({
            ...prevLoading,
            [id]: true,
        }))
        try {
            const blob = await ProductService.getDownloadFile(id)

            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = 'downloadedFile.pdf'
            document.body.appendChild(a)
            a.click()
            a.remove()
            window.URL.revokeObjectURL(url)
        } catch (error) {
            const axiosError = error as AxiosError
            console.log('🚀 ~ handleDownloadFile ~ error:', error)
            notificationMessage({
                type: 'error',
                message: `ไม่สามารถดาวน์โหลดไฟล์ได้ กรุณาตรวจสอบและลองใหม่ หรือหากปัญหายังคงอยู่ กรุณาติดต่อแอดมิน: ${axiosError?.message}`,
            })
        } finally {
            setLoadingRow((prevLoading) => ({
                ...prevLoading,
                [id]: false,
            }))
        }
    }

    const handleReject = async (record: IPaymentElement) => {
        try {
            await PaymentService.putPayment(
                record.refId,
                verifyStatus.UNVERIFIED
            )
            notificationMessage({
                type: 'success',
                message: 'อัปเดตสถานะการจ่ายเงินสำเร็จ',
            })
            fetchData()
        } catch (error) {
            const axiosError = error as AxiosError<{
                code: number
                message: string
            }>

            switch (axiosError?.response?.data.message) {
                case 'ไม่สามารถอนุมัติการจ่ายเงินได้ เนื่องจากผู้ใช้งานได้ทำการยกเลิกรายการเรียบร้อยแล้ว':
                    notificationMessage({
                        type: 'error',
                        message:
                            'ไม่สามารถอนุมัติการจ่ายเงินได้ เนื่องจากผู้ใช้งานได้ทำการยกเลิกรายการเรียบร้อยแล้ว',
                    })
                    window.location.reload()
                    break
                default:
                    notificationMessage({
                        type: 'error',
                        message: 'อัปเดตสถานะการจ่ายเงินไม่สำเร็จ',
                    })
                    window.location.reload()
                    break
            }
        }
    }

    const columns: ColumnsType<IPaymentElement> = [
        {
            title: 'รหัสคำสั่งซื้อ',
            key: 'refId',
            dataIndex: 'refId',
            width: '200px',
        },
        {
            title: 'ชื่อสินค้า',
            key: 'productName',
            dataIndex: 'productName',
            width: '200px',
            sorter: (a, b) => a.productName.localeCompare(b.productName),
            render: (_, record) => (
                <div style={{ lineBreak: 'auto', width: '200px' }}>
                    {record.productName}
                </div>
            ),
        },
        {
            title: 'หมวดหมู่สินค้า',
            key: 'productCategoryId',
            dataIndex: 'productCategoryId',
            width: '200px',
            render: (_, record) => (
                <div style={{ lineBreak: 'auto', width: '200px' }}>
                    {record.productCategoryId}
                </div>
            ),
        },
        {
            title: 'ชื่อผู้ใช้',
            key: 'displayName',
            dataIndex: 'displayName',
            width: '200px',
            render: (_, record) => (
                <div style={{ lineBreak: 'auto', width: '200px' }}>
                    {record.displayName}
                </div>
            ),
        },
        {
            title: 'สถานะ',
            key: 'status',
            dataIndex: 'status',
            width: '550px',
            render: (_, record) => {
                let statusText = ''
                let statusStyle = {
                    display: 'flex',
                    borderRadius: '5px',
                    padding: '5px',
                    width: '150px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    whiteSpace: 'nowrap',
                } as React.CSSProperties

                switch (record.status) {
                    case 'NO_STATUS':
                        statusText = '-'
                        statusStyle = {
                            ...statusStyle,
                            border: '1px solid black',
                            backgroundColor: 'black',
                            color: 'white',
                        }
                        break
                    case 'FAILED':
                        statusText = 'จ่ายเงินไม่สำเร็จ'
                        statusStyle = {
                            ...statusStyle,
                            border: '1px solid #DC3545',
                            backgroundColor: '#DC3545',
                            color: '#ffffff',
                        }
                        break
                    case 'WAITING_VERIFY':
                        statusText = 'กำลังรอการตรวจสอบ'
                        statusStyle = {
                            ...statusStyle,
                            border: '1px solid #FFC107',
                            backgroundColor: '#FFC107',
                            color: '#ffffff',
                        }
                        break
                    case 'VERIFYING':
                        statusText = 'กำลังยืนยันการตรวจสอบ'
                        statusStyle = {
                            ...statusStyle,
                            border: '1px solid blue',
                            backgroundColor: 'blue',
                            color: 'white',
                        }
                        break
                    case 'VERIFIED':
                        statusText = 'จ่ายเงินสำเร็จ'
                        statusStyle = {
                            ...statusStyle,
                            border: '1px solid #28A745',
                            backgroundColor: '#28A745',
                            color: '#ffffff',
                        }
                        break
                    case 'UNVERIFIED':
                        statusText = 'ไม่อนุมัติการจ่ายเงิน'
                        statusStyle = {
                            ...statusStyle,
                            border: '1px solid #FF8585',
                            backgroundColor: '#FF8585',
                            color: '#ffffff',
                        }
                        break
                }

                return (
                    <div
                        style={{
                            lineBreak: 'auto',
                            width: '100px',
                            ...statusStyle,
                        }}
                    >
                        {statusText}
                    </div>
                )
            },
        },
        {
            title: 'วันที่ชำระเงิน',
            key: 'verifyDate',
            dataIndex: 'verifyDate',
            width: '150px',
            render: (_, record) => {
                const formattedDate =
                    record.verifyDate &&
                    dayjs(record.verifyDate).format('YYYY-MM-DD') !==
                        '0001-01-01'
                        ? dayjs(record.verifyDate).format('DD/MM/YYYY')
                        : 'N/A'
                return (
                    <div style={{ lineBreak: 'auto', width: '150px' }}>
                        {formattedDate}
                    </div>
                )
            },
        },
        {
            title: 'ราคา',
            key: 'price',
            dataIndex: 'price',
            width: '100px',
            render: (_, record) => {
                const formattedPrice = `${record.price.toLocaleString()} บาท`
                return (
                    <div style={{ lineBreak: 'auto', width: '100px' }}>
                        {formattedPrice}
                    </div>
                )
            },
        },
        {
            title: 'หลักฐานการชำระเงิน',
            key: 'slipURL',
            dataIndex: 'slipURL',
            width: '200px',
            render: (_, record) => (
                <div style={{ lineBreak: 'auto', width: '200px' }}>
                    {record.slipURL ? (
                        <a
                            href={record.slipURL}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            หลักฐานการชำระเงิน
                        </a>
                    ) : (
                        '-'
                    )}
                </div>
            ),
        },
        {
            title: 'ตรวจสอบการชำระเงิน',
            key: 'slipOkRes',
            dataIndex: 'slipOkRes',
            width: '200px',
            render: (_, record) => (
                <div style={{ lineBreak: 'auto', width: '200px' }}>
                    {record.slipOkRes ? (
                        <a onClick={() => showModal(record.slipOkRes)}>
                            ตรวจสอบการชำระเงิน
                        </a>
                    ) : (
                        '-'
                    )}
                </div>
            ),
        },
        {
            title: 'ดาวน์โหลดไฟล์ PDF',
            width: '200px',
            render: (_, record: IPaymentElement) => (
                <div style={{ lineBreak: 'auto', width: '200px' }}>
                    {loadingRow[record.refId] ? (
                        <Button 
                            type="default" 
                            disabled={true}
                            loading={loadingRow[record.refId]}
                        >
                            กำลังดาวน์โหลดไฟล์ PDF
                        </Button>
                    ) : (
                        <Button
                            type="default"
                            onClick={() => handleDownloadFile(record.refId)}
                        >
                            ดาวน์โหลดไฟล์ PDF
                        </Button>
                    )}
                </div>
            ),
        },
        {
            title: 'อนุมัติการชำระเงิน',
            width: '200px',
            render: (_, record: IPaymentElement) => (
                <div style={{ lineBreak: 'auto', width: '200px' }}>
                    <Button
                        type="primary"
                        onClick={() => showApprovalModal(record)}
                        disabled={
                            record.deleted === true ||
                            record.status === 'VERIFIED' ||
                            record.status === 'UNVERIFIED'
                        }
                    >
                        อนุมัติการชำระเงิน
                    </Button>
                </div>
            ),
        },
    ]

    const handlePageChange = (page: number, pageSize: number) => {
        setFilterAllPayment((prevFilter) => ({
            ...prevFilter,
            page,
            pageSize,
        }))
    }

    return (
        <>
            <Table
                components={{
                    body: {
                        row: Row,
                    },
                }}
                scroll={{ x: 1400 }}
                rowKey="refId"
                columns={columns}
                dataSource={dataSource?.data}
                pagination={{
                    current: dataSource?.page,
                    pageSize: dataSource?.pageSize,
                    total: dataSource?.total,
                    onChange: handlePageChange,
                }}
            />
            <Modal
                title="ตรวจสอบการชำระเงิน"
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                footer={[
                    <Button key="submit" type="primary" onClick={handleModalOk}>
                        Ok
                    </Button>,
                ]}
            >
                {modalContent && <SlipOkResDetails slipOkRes={modalContent} />}
            </Modal>

            <Modal
                title="คุณต้องการอนุมัติการชำระเงินนี้หรือไม่?"
                open={isApprovalModalVisible}
                onCancel={() => setIsApprovalModalVisible(false)}
                footer={[
                    <Button key="back" onClick={handleApprovalCancel}>
                        ไม่อนุมัติ
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleApprovalOk}
                    >
                        อนุมัติ
                    </Button>,
                ]}
                maskClosable={false}
                closable={true}
            ></Modal>
        </>
    )
}
