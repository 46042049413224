import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios'

export const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api/admin`,
})

export const onFullfilledRequest = (
    config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
    return new Promise<InternalAxiosRequestConfig>((resolve) => {
        const token = window.localStorage.getItem('access_token')
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }
        resolve(config)
    })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onRejectedRequest = (error: Error): Promise<any> => {
    return Promise.reject(error)
}

export const onFullfilledResponse = (
    response: AxiosResponse
): AxiosResponse => {
    return response
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onRejectedResponse = (error: Error): Promise<any> => {
    return Promise.reject(error)
}

axiosInstance.interceptors.request.use(onFullfilledRequest, onRejectedRequest)
axiosInstance.interceptors.response.use(
    onFullfilledResponse,
    onRejectedResponse
)
